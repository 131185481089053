:root{
    // core colours 
    --core-black:           #000000;
    --core-white:           #FFFFFF;
    --brand-orange:         #FF7900;
    --accessible-orange:    #F16E00;

    // supporting colours
    --supporting-blue:      #4BB4E6;
    --supporting-green:     #50BE87;
    --supporting-pink:      #FFB4E6;
    --supporting-purple:    #A885D8;
    --supporting-yellow:    #FFD200;

    // darker tints 
    --darker-blue:          #085EBD;
    --darker-green:         #0A6E31;
    --darker-pink:          #FF8AD4;
    --darker-purple:        #492191;
    --darker-yellow:        #FFB400;

    // lighter tints
    --lighter-blue:         #B5E8F7;
    --lighter-green:        #B8EBD6;
    --lighter-pink:         #FFE8F7;
    --lighter-purple:       #D9C2F0;
    --lighter-yellow:       #FFF6B6;

    // OBS grey
    --obs-grey:             #595959;

    // functional colours 
    --functional-blue:      #527EDB;
    --functional-green:     #32C832;
    --functional-red:       #CD3C14;
    --functional-yellow:    #FFCC00;

    // functional greys
    --functional-grey-1:    #333333;
    --functional-grey-2:    #666666;
    --functional-grey-3:    #999999;
    --functional-grey-4:    #CCCCCC;
    --functional-grey-5:    #DDDDDD;
    --functional-grey-6:    #EEEEEE;
}
:root{
    --main-bg-primary-color: #fafafa;
    --main-bg-primary-contrast-color: #eeeeee;
    --main-bg-secondary-color: #333333;
}

.boxed {
    border: solid 1px #c5c5c5 !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}

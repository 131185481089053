/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.bg-info {
  color: white !important;
  background-color: #527EDB !important;
  border-color: #527EDB !important;
}

.bg-warning {
  color: white !important;
  background-color: #FFCC00 !important;
  border-color: #FFCC00 !important;
}

.bg-danger {
  color: white !important;
  background-color: #CD3C14 !important;
  border-color: #CD3C14 !important;
}

div.card-header.border-danger.bg-danger.wrapping-none {
  color: white !important;
  background-color: #CD3C14 !important;
  border-color: #CD3C14 !important;
}

div.card-header.border-success.bg-success.wrapping-none {
  color: white !important;
  background-color: #32C832 !important;
  border-color: #32C832 !important;
}

div.card-header.border-warning.bg-warning.wrapping-none {
  color: white !important;
  background-color: #FFCC00 !important;
  border-color: #FFCC00 !important;
}

.table-danger {
  background-color: rgba(205, 60, 20, 0.3) !important;
}

.table-danger>th {
  background-color: rgba(205, 60, 20, 0.3) !important;
}

.table-danger>td {
  background-color: rgba(205, 60, 20, 0.3) !important;
}

.table-success {
  background-color: rgba(50, 200, 50, 0.3) !important;
}

.table-success>th {
  background-color: rgba(50, 200, 50, 0.3) !important;
}

.table-success>td {
  background-color: rgba(50, 200, 50, 0.3) !important;
}

.table-warning {
  background-color: rgba(255, 204, 0, 0.3) !important;
}

.table-warning>th {
  background-color: rgba(255, 204, 0, 0.3) !important;
}

.table-warning>td {
  background-color: rgba(255, 204, 0, 0.3) !important;
}

.alert-info {
  color: white !important;
  background-color: #527EDB !important;
  border-color: #527EDB !important;
}

.alert-warning {
  color: black !important;
  background-color: #FFCC00 !important;
  border-color: #FFCC00 !important;
}

.alert-danger {
  color: white !important;
  background-color: #CD3C14 !important;
  border-color: #CD3C14 !important;
}

.alert-success {
  background-color: #50BE87 !important;
}

.card {
  color: black;
}

.card-body {
  color: black;
}

.card-title {
  color: black;
}

mdb-card.card {
  color: black !important;
}

.mat-slide-toggle-thumb {
  background-color: #EEEEEE !important;
}

.main-content {
  color: black;
  height: 100%;
  background-color: #eeeeee;
}

.bg-dark {
  background-color: #101010 !important;
}

.mat-cell {
  background-color: #eeeeee !important;
}

.mat-paginator-container {
  background-color: #eeeeee !important;
}

.pointer {
  cursor: pointer;
}

table {
  width: 100%;
}

tr.detail-row {
  height: 0;
}

tr.element-head th {
  color: black !important;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label-active {
  color: black;
  background-color: #C8C8C8;
}

::ng-deep .mat-tab-list .mat-tab-labels {
  color: blue;
  background-color: #ddd;
}

.search-container {
  overflow: unset !important;
}

.mat-tooltip {
  font-size: medium;
}